import * as React from "react";
import { Layout } from "../components/layout";
import { useDonationPage } from "../services/firestore";
import { graphql } from "gatsby";

const DonationPage = ({data}) => {
  const page = useDonationPage(data.allDonationPages.nodes[0]);
  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
    buttons: page.buttons,
    title: page.title,
    fullscreen: true,
  };
  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Nous soutenir"}/>
  );
};

export const query = graphql`
  query {
    allDonationPages {
      nodes {
        heroBannerType
        heroBannerImage {
          image
          src
        }
        buttons {
          text
          url
          subject
          default
        }
      }
    }
  }
`;

export default DonationPage;
